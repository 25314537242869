import React from 'react';
import Container from 'common/components/UI/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Section, { SectionContent } from './terms.style';

const TermsSection = () => {
    const data = useStaticQuery(graphql`
        query TermsConditionsQuery {
            iubendaDocument {
                termsAndConditions
            }
        }
    `);

    const { termsAndConditions } = data.iubendaDocument;

    return (
        <Section id="terms">
            <Container width="1400px">
                <SectionContent>
                    <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                </SectionContent>
            </Container>
        </Section>
    );
};

export default TermsSection;
